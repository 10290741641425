import { removeCookie } from '@/utils/cookie';

export const handleError = (self, err, withOut = false) => {
    let code = false;
    let message;

    if (err.response.data) {
        if (err.response.data.errors && err.response.data.errors.length > 1) {
            message = err.response.data.errors[0].msg;
        } else if (
            err.response.data.errcode &&
            self.$i18n.t('error.' + err.response.data.errcode)
        ) {
            message = self.$i18n.t('error.' + err.response.data.errcode);
            code = err.response.data.errcode;
        } else {
            message = err.message;
        }
    }
    //console.log(code);
    if (
        code &&
        ['tokeninvalid', 'tokeninvalid', 'tokenusernotfound'].indexOf(code) > -1
    ) {
        removeCookie('user-token');
        self.$router.push('login');
    } else {
        if (!code || (code && code != withOut)) {
            self.$notify({ type: 'danger', message });
        }

        return code;
    }
};

export const validateCreditCard = function (number) {
    var cardTypes = [
        {
            name: 'amex',
            pattern: /^3[47]/,
            validlength: 15
        },
        /* {
        name: 'dinersclub',
        pattern: /^(30[0-5]|36|38)/,
        validlength: 14
      }, */ {
            name: 'visa',
            pattern: /^4/,
            validlength: 16
        },
        {
            name: 'mastercard',
            pattern: /^5[1-5]/,
            validlength: 16
        },
        {
            name: 'troy',
            pattern: /^36/,
            validlength: 14
        },
        {
            name: 'troy',
            pattern: /^979/,
            validlength: 16
        },
        {
            name: 'troy',
            pattern: /^657/,
            validlength: 16
        }
    ];
    let cardType = getCardType(cardTypes, number.replace(/\s/g, ''));
    if (cardType) {
        return cardType;
    }
    return false;
};

const getCardType = function (cardTypes, number) {
    for (let i = 0, len = cardTypes.length; i < len; i++) {
        if (number.match(cardTypes[i].pattern)&&number.length==cardTypes[i].validlength) {
            return cardTypes[i];
        }
    }
    return false;
};
